<template>
    <p class="text__error">
        <slot />
    </p>
</template>

<script>
    export default {
        name: 'ErrorText',
    };
</script>

<style lang="scss" scoped>
    .text__error {
        margin-top: 3px;

        color: $danger;
    }
</style>
