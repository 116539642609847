<template>
    <div class="page__wrapper">
        <div class="page__inner">
            <h1>{{ $t('common.termsAndConditions') }}</h1>
            <div class="content">
                <h2 class="mt-32">{{ $t('termsAndConditions.overview') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph01') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section1') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph11') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph12') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph13') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph131') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph132') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph133') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph134') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph14') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph15') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section2') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph21') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph22') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph23') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph24') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section3') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph31') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph32') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph33') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph34') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph35') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph36') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section4') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph41') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph42') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph43') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph44') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph45', { email: 'info@cityrush.lt' }) }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph46') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph47') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section5') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph51') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph52') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph53') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph54') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph55') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section6') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph61') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph62') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph63') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph64') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section7') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph71') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph72') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph721') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph722') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph723') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph73') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph74', { email: 'info@cityrush.lt' }) }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section8') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph81') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph82') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph83') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph831') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph832') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph833') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph834') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph835') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph84') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph85') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph851') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph852') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph853') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph854') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph855') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph856') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph857') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph858') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph86') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section9') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph91') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph92') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph93') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section10') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph101') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1011') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1012') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1013') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1014') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph102') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1021', { email: 'info@cityrush.lt' }) }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1022') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1023') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1024') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1025') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1026') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1027') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1028') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph1029') }}</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section11') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph111') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph112') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph113') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph114') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph115') }}</p>

                <h2 id="discount" class="mt-32">{{ $t('termsAndConditions.section13') }}</h2>
                <p class="mt-24">12.1. Nuolaidos kodas tai speciali raidžių arba/ir skaičių kombinacija, leidžianti įsigyti elektroninėje parduotuvėje internetiniu adresu www.cityrush.lt esančias prekes/paslaugas pigiau. </p>
                <p class="mt-24">12.2. Nuolaidos kodas siunčiamas pirkėjo el. pašto adresu, nurodomas www.cityrush.lt internetinėje svetainėje arba kitais rinkodaros kanalais Cityrush vykdant reklamos kampaniją pagal atitinkamos kampanijos sąlygas.</p>
                <p class="mt-24">12.3. Cityrush kiekvieną kartą nustato nuolaidos kodo galiojimo laiką. Nuolaidos kodai gali turėti ribotą galiojimo laiką, kuris gali būti nurodomas kartu su siunčiamu/rodomu nuolaidos kodu.</p>
                <p class="mt-24">12.4. Cityrush pasilieka teisę keisti nuolaidos galiojimo laiką atsižvelgiant į atitinkamos kampanijos rezultatus, trumpinant arba ilginant nuolaidos galiojimo laiką.</p>
                <p class="mt-24">12.5. Nuolaidos kodui gali būti taikomas minimalios užsakymo sumos reikalavimas. Tokiu atveju nuolaidos kodas bus taikomas tik nustatytos ar didesnės vertės užsakymams. Kiekvieną kartą ši vertė gali būti skirtinga ir nurodoma nuolaidos kode arba greta jo.</p>
                <p class="mt-24">12.6. Kiekvieno nuolaidos kodo vertę nustato Cityrush. Nuolaidos kodo vertė yra rodoma atitinkamame Nuolaidos kode arba greta jo.</p>
                <p class="mt-24">12.7. Nuolaidos kodas suteikia tam tikro dydžio nuolaidą procentais. Pirkėjas turi teisę pasinaudoti nuolaidos kodu. Nuolaidos kodo naudojimas nėra privalomas. </p>
                <p class="mt-24">12.8. Nuolaidos kodą galima pritaikyti daliai elektroninėje parduotuvėje esančių paslaugų/prekių. Nuolaidos kodas negalioja formuojant Individualų užsakymą arba perkant Dovanų kuponą.</p>
                <p class="mt-24">12.9. Cityrush, pagal atitinkamos kampanijos sąlygas, pasilieka teisę nuolaidos kodą taikyti tik perkant paslaugas/prekes elektroninėje parduotuvėje internetiniu adresu www.cityrush.lt. Tokiu atveju, nuolaidos kodas nebus taikomas atliekant/pratęsiant užsakymą el. paštu, telefonu,  socialiniuose tinkluose ar kitomis ryšio priemonėmis.</p>
                <p class="mt-24">12.10. Nuolaidos kodai nesumuojami. Vieno pirkimo metu galima panaudoti tik vieną nuolaidos kodą.</p>
                <p class="mt-24">12.11. Vieno pirkimo metu galima panaudoti tik nuolaidos kodą ar dovanų kupono kodą.</p>
                <p class="mt-24">12.12. Neišnaudojus visos nuolaidos kode/dovanų kupono kode nurodytos sumos, skirtumas negrąžinamas.</p>
                <p class="mt-24">12.13. Nuolaidų kodai į pinigus nekeičiami</p>

                <h2 class="mt-32">{{ $t('termsAndConditions.section12') }}</h2>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph121') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph122') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph123') }}</p>
                <p class="mt-24">{{ $t('termsAndConditions.paragraph124') }}</p>
            </div>
            <GetInTouchSection class="get-in-touch-section" :title="$t('main.faqSection.cantFindAnswers')"
                :with-image="false" />
        </div>
    </div>
</template>

<script>
import GetInTouchSection from '@/components/MainSections/GetInTouchSection';
import { useRoute } from 'vue-router';
export default {
    name: 'TermsAndConditions',
    components: { GetInTouchSection },
    setup() {
        const route = useRoute();

        return {
            route,
        }
    },
    methods: {
        scrollTo() {
            let section = this.route.query.section;

            setTimeout(function () {
                const el = document.querySelectorAll('#' + section);
                if (el[0]) {
                    el[0].scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }, 1000);
        }
    },
    mounted() {
        this.scrollTo();
    }
};
</script>

<style lang="scss" scoped>
.page__wrapper {
    @include column-align-stretch;

    padding-top: 25px;
    padding-bottom: 25px;

    .page__inner {
        width: 100%;
    }
}

@include media($lg) {
    .page__wrapper {
        @include row-align-start;

        padding-top: 50px;
        padding-bottom: 0;

        .page__inner {
            max-width: 600px;

            margin: 0 auto;

            .get-in-touch-section {
                @include column-align-center;
                justify-content: stretch;

                min-width: 100%;

                margin-bottom: 40px;
                margin-top: 20px;

                :deep(.touch-section__inner) {
                    width: 100%;
                }
            }
        }
    }
}

@include media($xl) {
    .page__wrapper {
        .page__inner {
            max-width: 800px;
        }
    }
}</style>
