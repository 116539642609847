<template>
    <div class="cr-textarea__container">
        <textarea
            class="cr-textarea"
            :value="modelValue"
            @input="onChange"
            placeholder=" "
            ref="textarea"
            :class="textareaClasses"
            :maxlength="maxLength"
            @focus="focus"
            @blur="blur"
        />
        <label
            class="cr-textarea__label"
            for="cr-textarea"
            :class="labelClasses"
            v-if="label"
            @click="$refs.textarea.focus()"
        >
            {{ displayedLabel }}
        </label>
        <p class="cr-textarea__help-text" v-if="helpText && !errors.length">
            {{ helpText }}
        </p>
        <div v-if="errors.length">
            <p v-for="(error, index) in errors" :key="index" class="cr-textarea__help-text__error">
                {{ isVuelidate ? error.$message : error }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CRTextarea',
        props: {
            modelValue: {
                type: [String, Number],
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            maxLength: {
                type: Number,
                default: null,
            },
            required: {
                type: Boolean,
                default: false,
            },
            helpText: {
                type: String,
                default: '',
            },
            errors: {
                type: [Array, String],
                default: () => [],
            },
            isVuelidate: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            height: {
                type: Number,
                default: 115,
            },
        },
        emits: ['update:modelValue', 'focus', 'blur', 'onChange'],
        data() {
            return {
                isEyeIconClicked: false,
                isFocused: false,
            };
        },
        methods: {
            focus() {
                this.isFocused = true;
                this.$emit('focus', true);
            },
            blur() {
                this.isFocused = false;
                this.$emit('blur', false);
            },
            handleEyeIconClick(type) {
                this.$refs.textarea.type = type;
                this.isEyeIconClicked = !this.isEyeIconClicked;
                this.$refs.textarea.focus();
            },
            onChange(event) {
                this.$emit('update:modelValue', event.target.value.trim());
                this.$emit('onChange', event.target.value.trim());
            },
        },
        computed: {
            labelClasses() {
                return [this.required && 'cr-label__required-field'];
            },
            textareaClasses() {
                return [this.errors.length && 'cr-textarea__error'];
            },
            displayedLabel() {
                return this.isFocused || this.modelValue
                    ? this.label
                    : this.placeholder
                    ? this.placeholder
                    : this.label;
            },
            calculatedHeight() {
                return `${this.height}px`;
            },
        },
    };
</script>

<style scoped lang="scss">
    .cr-textarea__container {
        @include column-align-start;

        position: relative;

        width: 100%;
    }

    .cr-textarea__label {
        position: absolute;
        left: 18px;
        top: 14px;

        font-size: $font-16;
        color: $grey-form-label;

        border-radius: $br-10;

        cursor: text;

        transition: transform 0.2s ease-in;
    }

    .cr-textarea {
        @include row-align-start-justify-between;

        padding: 10px 14px;

        width: 100%;
        height: v-bind(calculatedHeight);

        border: 2px solid $grey;
        border-radius: $br-5;

        outline: none;

        resize: none;
    }

    .cr-textarea:focus + .cr-textarea__label,
    textarea:not(:placeholder-shown) + .cr-textarea__label {
        transform: translateY(-23px) translateX(-6px) scale(0.875);
        transform-origin: 0 0;

        padding: 0 6px;
        font-weight: bold;

        background-color: $white;
        color: $grey-form-label;

        &::before {
            position: absolute;

            font-size: $font-22;
            font-weight: bold;

            padding-right: 6px;
            right: -16px;
            top: 2px;
        }
    }

    .cr-textarea:focus + .cr-label__required-field,
    textarea:not(:placeholder-shown) + .cr-label__required-field {
        padding-right: 24px;
        &::before {
            content: '*';
            top: -5px;
            right: 0;
        }

        &::after {
            content: '';
            margin-left: 0;
        }
    }

    .cr-textarea__label.cr-label__required-field {
        &::after {
            content: '*';
            margin-left: 4px;
        }
    }

    textarea[type='password'] {
        font-family: caption;
    }

    .icon {
        position: absolute;
        right: 17px;
        top: 17px;
    }

    .icon-crossed {
        top: 15px;
    }

    .cr-textarea__help-text {
        margin-top: 3px;

        color: $black;
        font-size: $font-12;
    }

    .cr-textarea__help-text__error {
        margin-top: 3px;

        color: $danger;
    }

    .cr-textarea__error {
        border-color: $danger;
    }
</style>
