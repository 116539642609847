<template>
    <div class="cr-input__container">
        <input
            class="cr-input"
            :value="modelValue"
            @input="onChange"
            :type="type"
            placeholder=" "
            ref="input"
            :class="inputClasses"
            :disabled="disabled"
            @focus="focus"
            @blur="blur"
            :name="name"
            :autocomplete="autocomplete"
        />
        <label class="cr-input__label" for="cr-input" :class="labelClasses" v-if="label" @click="$refs.input.focus()">
            {{ displayedLabel }}
        </label>
        <CopySvg v-if="withCopyIcon" class="icon-copy" @click.stop="handleCopyIconClick" />
        <EyeSvg
            class="icon"
            v-if="inputIsForPassword && !isEyeIconClicked && modelValue"
            @click.stop="handleEyeIconClick('text')"
        />
        <EyeCrossedSvg
            class="icon icon-crossed"
            v-if="inputIsForPassword && isEyeIconClicked && modelValue"
            @click.stop="handleEyeIconClick('password')"
        />
        <p class="cr-input__help-text" v-if="helpText && !errors.length">
            {{ helpText }}
        </p>
        <div v-if="$slots['helpSlot']">
            <slot name="helpSlot" />
        </div>
        <div v-if="errors.length">
            <ErrorText v-for="(error, index) in errors" :key="index">
                {{ isVuelidate ? error.$message : error }}
            </ErrorText>
        </div>
    </div>
</template>

<script>
    import CopySvg from '@/assets/icons/buttons/copy-black.svg?inline';
    import EyeSvg from '@/assets/icons/inputs/eye.svg?inline';
    import EyeCrossedSvg from '@/assets/icons/inputs/eye-crossed.svg?inline';
    import ErrorText from '@/components/Common/Tags/ErrorText.vue';
    import { TYPE } from 'vue-toastification';

    export default {
        components: { EyeSvg, EyeCrossedSvg, ErrorText, CopySvg },
        name: 'CRInput',
        props: {
            autocomplete: {
                type: [String, Boolean],
                default: false,
            },
            modelValue: {
                type: [String, Number],
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            required: {
                type: Boolean,
                default: false,
            },
            isVuelidate: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            withCopyIcon: {
                type: Boolean,
                default: false,
            },
            helpText: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'text',
            },
            errors: {
                type: [Array, String],
                default: () => [],
            },
            placeholder: {
                type: String,
                default: '',
            },
            max: {
                type: [Number, null],
                default: null,
            },
            min: {
                type: [Number, null],
                default: null,
            },
        },
        emits: ['update:modelValue', 'focus', 'blur', 'onChange'],
        data() {
            return {
                isEyeIconClicked: false,
                isFocused: false,
            };
        },
        computed: {
            inputIsForPassword() {
                return this.type === 'password';
            },
            labelClasses() {
                return [this.required && 'cr-label__required-field'];
            },
            inputClasses() {
                return [
                    this.errors.length && 'cr-input__error',
                    this.inputIsForPassword && 'cr-input__password',
                    this.withCopyIcon && 'cr-input__copy',
                ];
            },
            displayedLabel() {
                return this.isFocused || this.modelValue
                    ? this.label
                    : this.placeholder
                    ? this.placeholder
                    : this.label;
            },
        },
        methods: {
            focus() {
                this.isFocused = true;
                this.$emit('focus', true);
            },
            blur() {
                this.isFocused = false;
                this.$emit('blur', false);
            },
            handleEyeIconClick(type) {
                this.$refs.input.type = type;
                this.isEyeIconClicked = !this.isEyeIconClicked;
                this.$refs.input.focus();
            },
            onChange(event) {
                let value = event.target.value;
                if (this.max || this.min) {
                    value = this.validateMaxAndMinValue(value);
                }

                this.$emit('update:modelValue', value.trim());
                this.$emit('onChange', value.trim());
            },

            validateMaxAndMinValue(value) {
                if (value !== '') {
                    if (this.min && +value < +this.min) {
                        value = this.min;
                    }
                    if (this.max && +value > +this.max) {
                        value = this.max;
                    }
                }
                return value;
            },

            async handleCopyIconClick() {
                try {
                    await navigator.clipboard.writeText(this.modelValue);
                    this.$filters.toast(this.$t('common.linkCopied'), { type: TYPE.SUCCESS });
                } catch (error) {
                    this.$filters.toast(this.$t('common.cantCopy'));
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .cr-input__container {
        @include column-align-start;

        position: relative;

        width: 100%;
    }

    .cr-input {
        @include row-align-start-justify-between;

        padding: 5px 14px;

        width: 100%;
        min-height: 45px;

        border: 2px solid $grey;
        border-radius: $br-5;

        outline: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }
    }

    .cr-input__label {
        position: absolute;
        left: 18px;
        top: 14px;

        font-size: $font-16;
        color: $grey-form-label;

        border-radius: $br-10;
        padding-top: 1px;
        padding-left: 1px;

        cursor: text;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        max-width: calc(100% - 35px);

        transition: transform 0.2s ease-in;
    }

    .cr-input:focus + .cr-input__label,
    input:not(:placeholder-shown) + .cr-input__label {
        transform: translateY(-33px) translateX(-6px) scale(0.875);
        transform-origin: 0 0;

        padding: 0 6px;
        padding-top: 10px;
        font-weight: bold;

        background-color: $white;
        color: $grey-form-label;

        &::before {
            position: absolute;

            font-size: $font-22;
            font-weight: bold;

            padding-right: 6px;
            right: -16px;
            top: 2px;
        }
    }

    .cr-input:focus + .cr-label__required-field,
    input:not(:placeholder-shown) + .cr-label__required-field {
        padding-right: 24px;
        &::before {
            content: '*';
            top: 0;
            right: 0;
        }

        &::after {
            content: '';
            margin-left: 0;
        }
    }

    .cr-input__label.cr-label__required-field {
        &::after {
            content: '*';
            margin-left: 4px;
        }
    }

    input[type='password'] {
        font-family: caption;
    }

    input[type='time'],
    input[type='date'] {
        @include row-align-center;
    }

    .cr-input__copy {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 50px;

        background: $white;
        cursor: text;
    }

    .icon-copy {
        position: absolute;
        right: 17px;
        top: 10px;

        cursor: pointer;
    }

    .icon {
        position: absolute;
        right: 17px;
        top: 17px;
    }

    .icon-crossed {
        top: 15px;
    }

    .cr-input__help-text {
        margin-top: 3px;

        color: $black;
        font-size: $font-12;
    }

    .cr-input__error {
        border-color: $danger;
    }

    .cr-input__password {
        padding-right: 45px;
    }
</style>
